.layout-user {
  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .profile-picture-avatar {
    img {
      border-radius: 50%;
    }
  }

  .text-bold {
    font-weight: bold;
  }

  .panel-user {
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #004885, #009ad8);
    }
  }

  .font-size-16 {
    font-size: 16px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .onMouseOver:hover {
    background-color: white;
    cursor: pointer;
  }

  .hr-style {
    padding: 0;
    color: white;
    margin-top: 0px;
  }

  .red-on-mouse-over:hover {
    color: red;
  }

  .blue-on-mouse-over:hover {
    color: blue;
  }

  .profile-picture {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .profile-picture:hover {
    cursor: pointer;
  }

  .button-save-picture {
    margin-top: 13px;
    margin-bottom: 10px;
  }

  .button-cancel-picture {
    margin-left: 10px;
    margin-top: 13px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .button-upload-image {
    font-size: 14px;
    padding: 0.429em 1em;
    color: white;
    background-color: #007ad9;
    border-radius: 3px;
  }

  .pencil-position {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .height-65 {
    height: 65px;
  }

  .background-color-white {
    background-color: white;
  }

  .padding-0 {
    padding: 0px;
  }

  .text-align-center {
    text-align: center;
  }

  .color-white {
    color: white;
  }

  .text-align-right {
    text-align: right;
  }
}
