.p-inputnumber {
  border: 1px solid $DUSTY_GRAY;
  border-radius: 0.625rem;

  .p-inputtext,
  .p-inputtext:hover,
  .p-inputtext:focus {
    border: none;
  }

  .p-button.p-button-text,
  .p-button.p-button-text:hover,
  .p-button.p-button-text:enabled:active {
    color: $SCORPION;
    box-shadow: none;
    background: none;
  }
}
