.layout-app-header {
  background: $BRINK_PINK;
  height: auto;
  top: 0px;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
  padding: 0.25rem;
  color: #ffffff;
  z-index: 999;
  right: 0;

  .header-text {
    color: white;
    font-size: 2.5rem;
    font-weight: lighter;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
}
