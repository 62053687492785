.layout-statistic {
  .calendar {
    p {
      color: $textColor;
      font-weight: 700;
      padding-top: 1rem;
    }

    .p-button {
      background-color: white !important;
      color: $buttonGrayColor !important;
      border-color: $buttonLightGrayColor !important;
      border-left: none;
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }

    .p-inputtext {
      border-right: none;
    }
  }

  .report-card {
    .card {
      background-color: $LIGHT_GREY;
      border: none;
    }

    .count {
      color: $BRINK_PINK;
      font-weight: bolder;
      font-size: xx-large;
    }

    .action {
      color: $textColor;
      font-weight: 700;
      white-space: pre-line;
    }

    .info {
      font-size: 1.5em;
    }

    .info:hover {
      font-size: 1.5em;
      color: $BRINK_PINK;
      cursor: pointer;
    }

    .card-info {
      cursor: pointer;
    }
  }
}
