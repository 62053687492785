.error-layout {
  height: 71vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 20vh;
    color: $DUSTY_GRAY;
  }

  .info {
    font-size: 5vh;
    color: $SCORPION;
  }
}
