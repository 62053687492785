.layout-header {
  font-family: "Apis";
  @media screen and (min-width: 801px) {
    .max-width-800 {
      display: none;
    }
    .min-width-801 {
      //height: 100px;
      background: $BRINK_PINK;
      color: white;
      font-weight: bold;
      display: flex;
      justify-content: center;
      a {
        color: white;
        text-decoration: none;
      }
      a:hover {
        text-decoration: none;
        color: white;
      }
      .item-style {
        width: 140px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item-style:hover {
        background-color: #c2e7f6;
        color: $BRINK_PINK;
        cursor: pointer;
        border-radius: 7px;
      }
    }
    .logout {
      width: 100px;
      height: 40px;
      position: absolute;
      right: 11vw;
      top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logout:hover {
      background-color: rgb(42, 145, 139);
      cursor: pointer;
      border-radius: 7px;
    }
    .user-profile-avatar:hover {
      cursor: pointer;
    }
    .full-width {
      width: 100%;
    }
    .user-profile-avatar {
      width: 70px;
      padding: 5px;
      .align-center {
        display: flex;
        align-items: center;
      }
    }
    li.p-submenu-header:hover {
      cursor: default;
    }
    li.p-submenu-header {
      border-bottom: 1px solid gray;
      background-color: white;
    }
  }

  .button-bottom-border {
    border-bottom: 2px solid $BRINK_PINK;
  }

  .active .button-bottom-border {
    border-bottom: 2px solid #ffffff;
  }

  .p-avatar {
    width: 3rem;
    img {
      width: 3rem;
      height: 3rem;
    }
  }

  @media only screen and (max-width: 800px) {
    .min-width-801 {
      display: none;
    }
    .max-width-800 {
      height: 100px;
      background: $BRINK_PINK;
      display: flex;
      align-items: center;
      .p-button {
        background-color: $buttonLightGrayColor;
      }
      .p-button:enabled:hover {
        border: $buttonLightGrayColor;
      }
    }
    .menuButton {
      margin-left: 15px;
      background-color: #4fcaff;
      border: "none";
      font-size: "20px";
    }
    .bars-icon {
      margin-left: 15px;
      background-color: rgb(79, 202, 255);
      border: none;
      font-size: 20px;
    }
  }
}

.p-menu .p-submenu-header {
  padding: 0rem;
}
