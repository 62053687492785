$white: #ffffff;
$orange: #eaab00;
$blue: #00a0da;
$dark-blue: #001864;
$red: #e64a0d;
$silver: #555454;
$green: #6df533;
$dark-green: #018a11;
$grey: grey;

$TRANSPARENT: #0000;

$BRINK_PINK: #fb647f;
$SWEET_PINK: #fc92a5;
$WHITE: #ffffff;
$SCORPION: #5a5a5a;
$DUSTY_GRAY: #8a8a8a;
$SILVER: #9e9e9e;
$RED: #ff0000;
$LIGHT_GREY: #f0f0f0;
