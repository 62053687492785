.layout-profile {
  display: flex;
  justify-content: center;

  .password-dialog {
    max-width: 40%;
    min-width: 30%;
    max-height: 40%;
    min-height: 30%;
  }

  .password-panel {
    margin-top: 20px;
  }

  .btn-small {
    font-size: 14px;
    padding: 0.429em 1em;
    color: white;
    width: auto;
  }

  .btn-small:hover {
    cursor: pointer;
    font-size: 14px;
    padding: 0.429em 1em;
    color: white;
    width: auto;
  }

  .text-center {
    text-align: center;
  }

  .button-save-picture {
    margin-left: 10px;
    margin-right: 10px;
  }

  .button-upload-image {
    font-size: 14px;
    padding: 0.429em 1em;
  }

  .profile-picture {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 50px;
    margin-left: 55px;
  }

  .button-save {
    background-color: #fbde8c;
    border-color: #fbde8c;
    color: $BRINK_PINK;
  }

  .button-save:hover {
    background-color: #fbde8c !important;
    border-color: #fbde8c !important;
    color: $BRINK_PINK !important;
  }

  .button-cancel-update {
    background-color: $BRINK_PINK;
    border-color: $BRINK_PINK;
    color: #fbde8c;
  }

  .button-cancel-update:hover {
    background-color: $BRINK_PINK !important;
    border-color: $BRINK_PINK !important;
    color: #fbde8c !important;
  }

  .profile-picture:hover {
    cursor: pointer;
  }

  .avatar-editor-size {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }

  .p-panel {
    .p-panel-header {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: #c2e7f6;
      border-color: #c2e7f6;
    }
    .p-panel-content {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .p-panel-title {
      color: $BRINK_PINK;
    }
  }
  .p-checkbox-label {
    display: contents;
  }

  .button-save {
    width: 115px;
    margin-right: 5px;
  }

  .button-for-password {
    width: 160px;
  }

  .panel-width {
    width: 700px;
  }

  .edit-user-label {
    font-size: 12px;
    line-height: 1;
    position: absolute;
    pointer-events: none;
    margin-top: -1rem;
    margin-left: 5px;
  }

  .user-data {
    margin-top: 50px;
  }

  .title {
    padding: 0.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
  }
  .top-border {
    width: 100%;
    height: 0rem;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
  }
  .separator::before {
    margin-right: 0.25em;
  }
  .separator::after {
    margin-left: 0.25em;
  }

  .text-box {
    height: 20rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    .orange {
      color: white;
      border-top-left-radius: 1rem;
      padding: 1rem;
    }
    .red {
      color: white;
      padding: 1rem;
    }
    .dark-blue {
      color: white;
      padding: 1rem;
    }
    .blue {
      color: white;
      border-top-right-radius: 1rem;
      padding: 1rem;
    }
  }
  .message {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .login-input {
    margin-bottom: 50px;
    .p-inputtext {
      border-radius: 6px;
    }
  }

  .login-input-password {
    margin-top: 20px;
    margin-bottom: 30px;
    .p-inputtext {
      border-radius: 6px;
    }
  }

  .login-button {
    .p-button-text {
      font-size: 15px;
      font-weight: bold;
    }
  }

  .panel-registration {
    width: 100%;

    .p-panel-content {
      min-height: 256px;
    }

    .p-panel-titlebar {
      font-size: large;

      color: white;
    }
    .div-size {
      width: 500px;
    }

    .button {
      background-color: white;
      height: 5rem;
      width: 20rem;
    }
    .action-text {
      margin-top: 5rem;

      font-size: 1.2rem;
    }
    .header-home {
      text-align: center;
      width: auto;
      height: 300px;
      padding: 50px;
    }
  }
}

.p-menu {
  .p-submenu-header {
    padding: 0rem;
  }
}

.p-dialog {
  border-width: 0px;
}

.p-toolbar {
  background-color: #f4f4f4;
  border-color: #c8c8c8;
}
