@font-face {
  font-family: "Apis";
  src: url("../../infrastructure/fonts/ApisWeb-Regular.woff2") format("woff2"), url("../../infrastructure/fonts/ApisWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "Black";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ExtraBold";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Light";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Thin";
  src: url("../../infrastructure/fonts/MPLUSRounded1c-Thin.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  font-family: "Medium";
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

.padding-left-right {
  padding-left: 10vw;
  padding-right: 10vw;
}

.max-height {
  height: 100vh;
}

.flex-end {
  align-self: flex-end;
}

.p-dialog {
  .p-dialog-header {
    background-color: $BRINK_PINK;
    border-color: $BRINK_PINK;
    color: $white;
  }
  .p-dialog-footer {
    color: $white;
  }
  .p-toolbar {
    background: none;
    border: none;
    color: $BRINK_PINK;
  }
}

.p-button:focus {
  box-shadow: none;
}

::placeholder {
  color: $DUSTY_GRAY;
  font-family: "Medium";
  font-size: 1rem;
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border: 1px solid $DUSTY_GRAY;
}

.p-inputtext:enabled:hover {
  border: 1px solid $DUSTY_GRAY;
}

.p-inputtext {
  border: 1px solid $DUSTY_GRAY;
  border-radius: 0.625rem;
  width: 100%;
  color: $SCORPION;
  padding: 0.5rem;
  padding-left: 1.5rem;
  font-family: "Medium";
  font-size: 1rem;
}

.p-dropdown {
  border-radius: 0.625rem;
}

.p-message {
  margin: 0;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin: 0;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.fileContainer {
  box-shadow: none !important;
  align-items: flex-start !important;
  margin: 0 !important;
  padding: 0 !important;
}

.fileContainer .chooseFileButton {
  border-radius: 50% !important;
  margin: 0 !important;
  padding: 1rem !important;
  width: 3.5rem;
  height: 3.5rem;
}

.large-button {
  width: 4rem !important;
  height: 4rem;
}

.large-button-font {
  font-size: 1.5rem;
}

.image-container {
  position: relative;
  width: 100%;

  .image {
    width: 100%;
    height: auto;
    border: 2px solid $SCORPION;
    border-radius: 1rem;
    padding: 1rem;
  }

  .remove-image-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: $buttonPomegranateColor;
    color: white;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
  }
}

.errorsContainer {
  position: absolute;
  left: 5rem;
}

.card-img-top {
  width: 100%;
  padding: 0.25rem;
  border-radius: 2rem !important;
  height: 10rem;
}

.card-date-style {
  font-size: 0.75rem;
  color: gray;
}

.image-width {
  width: 90%;
  background-color: $BRINK_PINK;
  border-radius: 2rem;
}

.p-card .p-card-body {
  padding: 0;
}

.article-content-title {
  font-family: "Regular";
  font-size: 2rem;
  color: $SCORPION;
  font-weight: bold;
  padding-top: 1rem;
}

.scorpion-color {
  color: $SCORPION;
}

.p-editor-container .p-editor-toolbar {
  background: transparent;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #dcdcdc;
}

.p-component img {
  max-width: 100%;
}

// SVE OVA NA DOLE JE PREUZETO IZ CSS-a SA WebSite-a, ocisceno od neki ociglednih viskova

// @media screen and (max-width: 800px) {
//   img.body-image {
//     width: 100%;
//   }
// }

// @media screen and (min-width: 801px) {
//   img.body-image {
//     height: auto;
//     margin-left: auto;
//     margin-right: auto;
//     width: 50%;
//   }
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: "Apis" !important;
// }
// p,
// div {
//   font-family: "Apis" !important;
// }

// .dropdown-toggle {
//   font-size: 1rem !important;
// }

// .dropdown-item {
//   font-size: 1rem !important;
// }

// .dropdown-item:hover {
//   background-color: #2a918b !important;
//   color: white !important;
// }

// .icon {
//   opacity: 0.7;
// }

// .header-menu {
//   align-self: center;
//   align-items: center;
//   display: flex;
//   width: 100%;
// }

// .menu_text {
//   color: black !important;
// }

// .dropdown_no_arrow::after {
//   display: none;
// }

// .small-container {
//   width: 95%;
//   margin: 0 auto;
// }

// .overlay {
//   background-color: rgba(0, 0, 0, 0.51);
//   width: 100%;
//   height: 100%;
// }

// .violet-overlay {
//   background-color: rgba(189, 140, 191, 0.51);
//   width: 100%;
//   height: 100%;
//   padding: 70px 0;
// }

// .white-overlay {
//   background-color: rgba(255, 255, 255, 0.7);
//   height: 100%;
//   width: 100%;
// }

// .margin_top_30 {
//   margin-top: 30px;
// }

// .service_blog h3.blog_head {
//   float: left;
//   background: #4fcaff;
//   text-align: center;
//   font-size: 24px;
//   text-transform: none;
//   margin: 0;
//   min-height: 50px;
//   line-height: 50px;
//   padding: 0 50px;
//   color: #fff;
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   max-width: 300px;
//   left: 15px;
// }

// .service_blog h3.blog_head::after {
//   width: 50px;
//   height: 50px;
//   content: "";
//   display: block;
//   position: absolute;
//   right: -20px;
//   background: #4fcaff;
//   top: 0;
//   transform: skew(-30deg);
// }

// h2 span,
// h1 span {
//   color: #bd8cbf;
// }

// li {
//   list-style-type: none;
// }

// a:hover {
//   text-decoration: none;
// }

// a,
// button {
//   transition: all 0.5s ease;
// }

// button {
//   padding: 0;
// }

// body {
//   font-size: 1.1rem;
// }

// /* navbar */

// nav.navbar {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 1000;
//   font-family: "Apis";
//   background: rgba(42, 145, 139, 0.7);
//   min-height: 95px;
//   margin-top: 77px;
// }

// nav.navbar a,
// nav.navbar ul li a {
//   color: #fff !important;
// }

// nav.navbar ul li {
//   padding: 0 15px;
//   position: relative;
// }

// nav.navbar .search-container {
//   position: relative;
// }

// nav.navbar form {
//   padding: 5px;
//   background-color: white;
//   position: absolute;
//   width: 260px;
//   display: none;
//   right: 0;
// }

// nav.navbar form input {
//   padding: 5px 10px;
//   border: none;
//   background-color: #fff;
//   width: 100%;
//   font-weight: 300;
//   font-size: 16px;
// }

// .dropdown-toggle {
//   background-color: transparent;
//   border-color: transparent;
//   color: #fff !important;
// }
// .dropdown-toggle:hover,
// .dropdown-toggle:active,
// .dropdown-toggle:focus,
// .dropdown-toggle:active,
// .open .dropdown-toggle,
// .dropdown-toggle[aria-expanded="true"] {
//   background-color: rgba(42, 145, 139, 1) !important;
//   border-color: rgba(42, 145, 139, 1) !important;
//   box-shadow: 0 0 0 0.2rem rgba(42, 145, 139, 1) !important;
// }

// .navbar-nav li a {
//   font-size: 18px;
//   font-weight: 300;
// }

// .layout_padding {
//   padding: 90px 0;
// }

// .about_section h3 {
//   font-size: 40px;
//   margin: 5px 0 25px 0;
//   font-weight: 600;
// }

// .about_section h4 {
//   color: #4fcaff;
//   font-size: 18px;
//   margin: 0;
// }

// .slider {
//   position: relative;
// }

// p {
//   font-size: 18px;
//   font-weight: 300;
// }

// .full {
//   width: 100%;
//   float: left;
//   margin: 0;
// }

// .text_align_center {
//   text-align: center;
// }

// .img-responsive {
//   max-width: 80%;
//   height: auto;
// }

// .white_font {
//   color: #fff !important;
// }

// .h3,
// h3 {
//   font-size: 40px;
//   font-weight: 700;
//   color: #3a3a3a;
//   text-transform: uppercase;
// }

// .contact-form {
//   padding: 55px 65px;
//   background: #4fcaff;
//   min-height: 600px;
//   margin-top: 60px;
// }

// .contact-form form input {
//   width: 100%;
//   background: #fff;
//   border: none;
//   height: 60px;
//   padding: 0 20px;
//   text-transform: uppercase;
//   margin: 15px 0;
// }

// .contact-form form textarea {
//   width: 100%;
//   background: #fff;
//   border: none;
//   min-height: 120px;
//   padding: 15px 20px;
//   text-transform: uppercase;
//   margin: 15px 0;
// }

// .contact-form form input[type="submit"] {
//   background: #fa3e19;
//   color: #fff;
//   width: 190px;
//   margin: 15px auto 0;
//   font-size: 19px;
//   font-weight: 300;
//   float: none;
//   display: flex;
//   cursor: pointer;
// }

// .contact-form form input[type="submit"]:hover,
// .contact-form form input[type="submit"]:focus {
//   background: #111;
//   color: #fff;
//   transition: ease all 0.5s;
// }

// /* header */

// header {
//   position: relative;
//   color: #fff;
//   font-family: "Apis", sans-serif;
// }

// header .container {
//   height: 100%;
// }

// header .container > div {
//   margin: 0 auto;
//   position: relative;
//   /*    text-align: center;*/
// }

// header .container > div h1 {
//   font-size: 90px;
// }

// header button {
//   background-color: transparent;
//   display: block;
//   border: 1px solid #fff;
//   border-radius: 50px;
//   padding: 0;
//   margin: 30px auto;
// }

// header button a {
//   padding: 10px 40px;
//   display: block;
//   color: #fff;
// }

// header button:hover {
//   background-color: #bd8cbf;
//   border: 1px solid #bd8cbf;
// }

// header button:hover a {
//   color: white;
// }

// h3.blog_head {
//   width: 100%;
//   float: left;
//   background: #fff;
//   text-align: center;
//   font-size: 25px;
//   text-transform: uppercase;
//   color: #090101;
//   margin: 0;
//   min-height: 75px;
//   line-height: 75px;
// }

// /* about us */

// .about-us {
//   position: relative;
//   padding: 100px 0;
//   font-family: "Open Sans", sans-serif;
// }

// .about-us .row > .col-lg-6:first-of-type {
//   position: relative;
//   left: 50px;
// }

// .about-us .small-container > p + div {
//   padding: 70px 0;
//   background-color: white;
//   box-shadow: 5px 5px 10px #d2cfcf, -5px -5px 10px #d2cfcf;
// }

// .about-us .small-container > p + div h4 {
//   margin-bottom: 40px;
// }

// .about-us .small-container .text-right p {
//   margin-bottom: 40px;
//   width: 80%;
//   float: right;
// }

// .about-us .small-container > p + div button {
//   background-color: #bd8cbf;
//   border: none;
// }

// .about-us .small-container > p + div a {
//   display: block;
//   color: #fff;
//   padding: 10px 40px;
// }

// .about-us .item {
//   width: 200px;
//   position: absolute;
//   color: white;
//   background-color: #bd8cbf;
// }

// .about-us .item p {
//   font-size: 15px;
// }

// .about-us .item img {
//   width: 100%;
//   height: 150px;
// }

// .about-us .item h5 {
//   margin: 5px 0;
// }

// .about-us .item p {
//   margin-bottom: 5px;
// }

// .about-us .item:first-of-type {
//   left: 405px;
//   bottom: 115px;
//   z-index: 2;
//   box-shadow: -2px 2px 10px #5d5959;
// }

// .about-us .item:nth-of-type(2) {
//   left: 105px;
//   bottom: 115px;
//   z-index: 2;
//   box-shadow: 2px 2px 10px #5d5959;
// }

// .about-us .item:nth-of-type(3) {
//   position: relative;
//   top: 162px;
//   left: -45px;
// }

// .about-us .item:nth-of-type(3) img {
//   position: absolute;
//   bottom: 70px;
//   right: 0px;
//   z-index: 0;
// }

// .about-us .item:last-of-type {
//   left: 268px;
//   top: 90px;
// }

// .about-us .first {
//   width: 100px;
//   height: 200px;
//   position: absolute;
//   background-color: #bd8cbf;
//   top: 140px;
//   z-index: -5;
// }

// .about-us .second {
//   width: 100px;
//   height: 200px;
//   position: absolute;
//   background-color: #bd8cbf;
//   bottom: 58px;
//   right: 34px;
//   z-index: -5;
// }

// .about-us .row > div > .item:nth-of-type(3) div {
//   position: relative;
//   z-index: 5;
//   background-color: #bd8cbf;
//   padding: 5px;
// }

// .blue_bt {
//   width: 255px;
//   height: 58px;
//   background: #4fcaff;
//   color: #fff;
//   float: left;
//   text-align: center;
//   line-height: 58px;
//   font-size: 20px;
//   font-weight: 300;
// }

// .blue_bt:hover,
// .blue_bt:focus {
//   background: #fff;
//   color: #0c2326;
// }

// /* media queries */

// @media (max-width: 1200px) {
//   /* about */

//   .about-us .row > .col-lg-6:first-of-type {
//     left: -10px;
//   }
//   .about-us .small-container .text-right p {
//     width: 70%;
//   }
//   .about-us .item {
//     width: 150px;
//   }
//   .about-us .item img {
//     height: 125px;
//   }
//   .about-us .item:first-of-type {
//     left: 440px;
//     bottom: 154px;
//   }
//   .about-us .item:nth-of-type(2) {
//     left: 167px;
//     bottom: 153px;
//   }
//   .about-us .item:nth-of-type(3) {
//     top: 149px;
//     left: 19px;
//   }
//   .about-us .item:last-of-type {
//     left: 305px;
//     top: 132px;
//   }

//   .about-us .row > div > .item:nth-of-type(3) img {
//     position: static;
//   }

//   .about-us .row > div > .item:nth-of-type(3) p {
//     display: none;
//   }
// }

// @media (max-width: 992px) {
//   /* header */

//   header h1 {
//     font-size: 65px !important;
//   }

//   /* navbar */

//   .navbar ul {
//     background-color: #4fcaff;
//   }
//   .navbar ul li {
//     margin: 10px 0;
//   }
//   nav.navbar form {
//     width: 100%;
//     position: static;
//   }

//   /* about */

//   .about-us .small-container > p + div {
//     padding: 50px 0;
//   }
//   .about-us .row > .col-lg-6:first-of-type {
//     height: 350px;
//     left: 50px;
//   }
//   .about-us .small-container .text-right p {
//     width: 100%;
//   }

//   /* footer */

//   footer .col-lg-3 {
//     margin-bottom: 40px;
//   }
// }

// @media (max-width: 768px) {
//   /* header */

//   header h1 {
//     font-size: 50px !important;
//   }

//   /* about */

//   .about-us .row > .col-lg-6:first-of-type {
//     display: none;
//   }
// }

// .navbar-light .navbar-toggler {
//   color: #000;
//   background: #4fcaff;
//   width: 50px;
//   padding: 0;
//   height: 45px;
//   border: none;
// }

// @media (min-width: 992px) and (max-width: 1199px) {
// }

// @media (min-width: 768px) and (max-width: 991px) {
//   .header-logo {
//     height: 60px;
//   }

//   .header-text {
//     font-size: 1rem;
//     letter-spacing: 0px;
//   }

//   nav.navbar {
//     margin-top: 60px;
//     min-height: 50px;
//   }

//   .ovarlay_slide_cont {
//     background: rgba(59, 151, 222, 0.9);
//     position: absolute;
//     width: 30%;
//     height: 80%;
//     margin: 10% 30%;
//     z-index: 1111111111;
//     top: 0;
//     left: 0;
//     padding: 50px 60px 70px;
//   }

//   .ovarlay_slide_cont h2 {
//     font-size: 30px;
//   }

//   .ovarlay_slide_cont p {
//     color: #fff;
//     font-weight: 300;
//     margin: 0 0 30px 0;
//     font-size: 10px;
//     padding: 0;
//     line-height: 28px;
//   }

//   .h3,
//   h3 {
//     font-size: 30px;
//   }

//   .about_section h3 {
//     font-size: 30px;
//   }

//   .about_section h4 {
//     color: #4fcaff;
//     font-size: 16px;
//     margin: 0;
//   }

//   h3.blog_head {
//     width: 100%;
//     float: left;
//     background: #fff;
//     text-align: center;
//     font-size: 18px;
//     text-transform: uppercase;
//     color: #090101;
//     margin: 0;
//     min-height: 50px;
//     line-height: 50px;
//   }

//   /* discovery */

//   .map-world {
//     width: 150px;
//     height: 100px;
//   }

//   /* new */

//   .news_tite {
//     text-align: center;
//     font-size: 15px;
//   }

//   .news_tite_divider {
//     width: 30px;
//     height: 2px;
//     background-color: #fff;
//     margin: 0 auto;
//     margin-top: 7%;
//     margin-bottom: 7%;
//   }

//   .service_blog h3.blog_head {
//     float: left;
//     background: #4fcaff;
//     text-align: center;
//     font-size: 18px;
//     text-transform: none;
//     margin: 0;
//     min-height: 50px;
//     line-height: 50px;
//     padding: 0 20px;
//     color: #fff;
//     position: absolute;
//     bottom: 0;
//     width: 65%;
//     max-width: 300px;
//     left: 15px;
//     font-weight: 500;
//   }

//   .service_blog h3.blog_head::after {
//     width: 50px;
//     height: 50px;
//     content: "";
//     display: block;
//     position: absolute;
//     right: -35px;
//     background: #4fcaff;
//     top: 0;
//     transform: skew(-30deg);
//   }

//   .contact-form {
//     padding: 40px 50px;
//     background: #4fcaff;
//     min-height: 600px;
//     margin-top: 60px;
//   }

//   .service_blog img {
//     width: 100%;
//   }
// }

// @media (max-width: 767px) {
//   .header_top {
//     width: 100%;
//   }

//   .header_logo {
//     height: 60px;
//   }

//   .header_top_blue {
//     font-size: 1rem;
//     letter-spacing: 0px;
//   }

//   nav.navbar {
//     margin-top: 60px;
//     min-height: 50px;
//   }

//   .layout_padding {
//     padding: 50px 0;
//   }

//   .about_section h3 {
//     font-size: 30px;
//     margin: 10px 0 25px 0;
//     font-weight: 700;
//     line-height: 32px;
//   }

//   .h3,
//   h3 {
//     font-size: 30px;
//     font-weight: 700;
//     color: #3a3a3a;
//     text-transform: uppercase;
//     margin-bottom: 15px;
//   }

//   h3.blog_head {
//     width: 100%;
//     float: left;
//     background: #fff;
//     text-align: center;
//     font-size: 20px;
//     text-transform: uppercase;
//     color: #090101;
//     margin: 0;
//     min-height: 55px;
//     line-height: 55px;
//   }

//   .service_blog h3.blog_head {
//     float: left;
//     background: #4fcaff;
//     text-align: center;
//     font-size: 22px;
//     text-transform: none;
//     margin: 0;
//     min-height: 50px;
//     line-height: 50px;
//     padding: 0 40px;
//     color: #fff;
//     position: absolute;
//     bottom: 0;
//     width: 70%;
//     max-width: 300px;
//     left: 15px;
//   }

//   .contact-form {
//     padding: 35px 40px;
//     background: #4fcaff;
//     min-height: 600px;
//     margin-top: 20px;
//   }

//   .contact-form form input {
//     margin: 10px 0;
//   }

//   #contact img {
//     margin-top: 30px;
//   }

//   .testimonial_blog {
//     background: #a1a1a1;
//     width: 100%;
//     padding: 30px 30px;
//     box-shadow: 10px 10px 0 0 #4fcaff;
//   }

//   .testimonial_blog p:first-child {
//     font-size: 30px;
//     font-weight: 500;
//     margin-bottom: 10px;
//   }

//   p {
//     font-size: 17px;
//     font-weight: 300;
//   }

//   .subcribe form {
//     height: auto;
//     background: #fff;
//     margin-top: 20px;
//     padding: 0;
//     font-weight: 300;
//     font-size: 19px;
//     display: block;
//   }

//   .subcribe form input {
//     margin: 0;
//     padding: 20px 30px;
//     width: 100%;
//     border: none;
//     font-size: 20px;
//     font-weight: 300;
//   }

//   .subcribe form button {
//     background: #fa3e19;
//     color: #fff;
//     width: 100%;
//     border: none;
//     font-size: 20px;
//     padding: 12px 0;
//   }

//   .subcribe form button:hover,
//   .subcribe form button:focus {
//     background: #111;
//     color: #fff;
//   }

//   .ovarlay_slide_cont {
//     display: none;
//   }

//   .service_blog img {
//     width: 100%;
//   }
// }

// table {
//   font-family: arial, sans-serif;
//   border-collapse: collapse;
//   width: 50%;
//   margin-right: auto;
//   margin-left: auto;
// }
// td,
// th {
//   border: 1px solid #dddddd;
//   text-align: left;
//   padding: 8px;
//   text-align: center;
// }

// tr:nth-child(even) {
//   background-color: #dddddd;
// }
