//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-datepicker table td.p-datepicker-today > span {
  color: $BRINK_PINK !important;
  background: $WHITE !important;
  border-color: transparent !important;
}

.p-datepicker table td > span.p-highlight {
  color: $WHITE !important;
  background: $BRINK_PINK !important;
  border-color: transparent !important;
}

.p-datepicker table th {
  padding: 0.5rem;
  text-align: center;
}
