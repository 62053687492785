.layout-user-administration {
  p {
    color: $textColor;
    font-weight: 700;
  }

  .p-button {
    padding: 0.5rem 3rem;
  }

  .dark-gray-button {
    padding: 0.3rem 0rem;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border-left: 0px;
    border-right: 0px;
    text-align: center;
  }

  tr {
    border: 1px solid #c8c8c8;
  }
}
