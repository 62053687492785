.datatable-layout {
  .create-article-button,
  .create-article-button:hover,
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: $BRINK_PINK;
    border: none;
    border-radius: 0.625rem;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
    border-bottom: 2px solid #c8c8c8;
    background: transparent;
    color: $textColor;
  }

  .p-datatable-tbody {
    border-top: 2px solid #c8c8c8;
  }

  .p-datatable .p-datatable-thead > tr > th {
    border: none;
    background: transparent;
    color: $textColor;
  }

  .p-paginator {
    background: transparent;
    border: none;
    padding-top: 1rem;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-radius: 25%;
  }

  .p-paginator .p-dropdown,
  .p-fluid .p-inputtext {
    border-radius: 0.5rem;
    border-color: $DUSTY_GRAY;
  }

  .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover,
  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
  .p-datatable .p-sortable-column .p-sortable-column-icon:hover,
  .p-datatable .p-sortable-column .p-sortable-column-icon,
  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    background: transparent;
    color: darkgray;
  }

  .p-datatable .p-sortable-column.p-highlight {
    color: #000000;
  }

  .p-datatable .p-sortable-column:focus,
  .p-button:enabled:focus {
    box-shadow: none;
  }
}

.datatable-layout .p-datatable .p-datatable-tbody > tr:hover > td {
  color: $BRINK_PINK;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: $BRINK_PINK;
  border: none;
}

.gray-button {
  background-color: $buttonLightGrayColor;
  border: $buttonLightGrayColor;
}

.dark-gray-button,
.gray-button:hover {
  background-color: $buttonGrayColor !important;
  border: $buttonGrayColor !important;
}

.red-button {
  background-color: $buttonPomegranateColor;
  border: $buttonPomegranateColor;
}

.red-button:hover {
  background-color: $buttonStrongRedColor !important;
  border: $buttonStrongRedColor !important;
}

.pink-button {
  background-color: $BRINK_PINK;
  border: $BRINK_PINK;
}

.pink-button:hover {
  background-color: $buttonPomegranateColor !important;
  border: $buttonPomegranateColor !important;
}

.read-update-button:focus .delete-button:focus,
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus,
.p-dialog {
  box-shadow: none;
}

.rounded-button {
  border-radius: 0.625rem;
}

.p-dialog .p-dialog-footer {
  border-top: none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.p-dialog .p-dialog-header {
  padding: 1.5rem 1rem 1.5rem 5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: #fff;
  border-color: #fff;
  color: $BRINK_PINK;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #fff;
  border-color: #fff;
  color: #5a5a5a;
}

.p-tabview .p-tabview-panels {
  border: none;
  padding-top: 3rem;
}

.p-tabview .p-tabview-panels,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-left: 0;
}

.p-dialog .p-dialog-content {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 0;
}

.p-inputnumber .p-inputtext {
  text-align: center;
}

.w-10-rem {
  width: 10rem !important;
}

.p-tabview-nav-container {
  position: fixed;
  z-index: 2;
  background-color: #fff;
  width: 54rem;
}
