.p-checkbox {
  border-color: $DUSTY_GRAY;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $BRINK_PINK;
  background: $BRINK_PINK;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: $DUSTY_GRAY;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $BRINK_PINK;
}
