.p-confirm-dialog {
  .p-dialog-content {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    padding-left: 4.5rem;
    padding-right: 5rem;
  }

  .p-dialog-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  .p-confirm-dialog-message {
    margin: 0;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin: 0rem;
  }

  .p-dialog-header-close-icon {
    color: $SCORPION;
  }
}
