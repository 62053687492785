.block-ui {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100000;
    background-color: black;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  